import * as blogActions from "./blogAction";

export const blogFeatureKey = "blogInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  allBlogs: [],
  details: {},
  blogCount: undefined,
  isBlogLike: false,
  allBlogComments: [],
  sendComment: false,
  commentCount: undefined,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // add address
    case blogActions.GET_ALL_BLOGS_REQUEST:
      return {
        ...state,
        // loading: true,
      };

    case blogActions.GET_ALL_BLOGS_SUCCESS:
      return {
        ...state,
        allBlogs: payload?.data,
        blogCount: payload?.blogCount[0]?.blogCount,
        // loading: false,
      };
    case blogActions.GET_ALL_BLOGS_FAILURE:
      return {
        ...state,
        // loading: false,
        errorMsg: payload,
      };

    case blogActions.GET_ONE_BLOG_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case blogActions.GET_ONE_BLOG_SUCCESS:
      return {
        ...state,
        details: payload?.data,
        loading: false,
      };
    case blogActions.GET_ONE_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update blogs:
    case blogActions.UPDATE_BLOG_VIEWS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case blogActions.UPDATE_BLOG_VIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case blogActions.UPDATE_BLOG_VIEWS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // like blogs:
    case blogActions.BLOG_LIKE_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case blogActions.BLOG_LIKE_SUCCESS:
      return {
        ...state,
        // loading: false,
        isBlogLike: true,
      };
    case blogActions.BLOG_LIKE_FAILURE:
      return {
        ...state,
        // loading: false,
        errorMsg: payload,
      };

    // get all blogs comment
    case blogActions.GET_ALL_BLOG_COMMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogActions.GET_ALL_BLOG_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allBlogComments: payload.data,
        commentCount: payload.count,
      };
    case blogActions.GET_ALL_BLOG_COMMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // comment to blog
    case blogActions.COMMENT_ON_BLOG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogActions.COMMENT_ON_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        sendComment: true,
      };
    case blogActions.COMMENT_ON_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update comment
    case blogActions.UPDATE_COMMENT_ON_BLOG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogActions.UPDATE_COMMENT_ON_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case blogActions.UPDATE_COMMENT_ON_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // delete blog
    case blogActions.DELETE_BLOG_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogActions.DELETE_BLOG_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case blogActions.DELETE_BLOG_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
